
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { translate, translateKey } from "@/hooks/TranslateService";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { successMessage } from "@/core/services/AlertService";
import AddCompanySettingModal from "@/components/modals/forms/AddCompanySettingModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "company-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
    AddCompanySettingModal,
  },
  setup() {
    const { t, locale } = useI18n();
    const route = useRoute();
    const store = useStore();

    const isFormSubmitted = ref(false);

    const submitButton = ref<HTMLElement | null>(null);
    const message_company_updated_succesfully = ref<string>(
      translate("FEEDBACK_COOKIE_COMPANY_EDIT_SUCCESSFUL")
    );

    const toYear = reactive(
      computed(() => {
        return localStorage.getItem("to_year_admin");
      })
    );

    const selectedUser = reactive(
      computed(() => {
        return JSON.parse(
          localStorage.getItem("simulate_user") as string
        );
      })
    );

    const defaultAccountInfo = computed(() => {
      return {
        userProfile: {
          id: selectedUser.value.id,
          user_name: selectedUser.value.user_name,
          email: selectedUser.value.email,
          ruolo_aziendale: selectedUser.value.ruolo_aziendale,
          telefono: selectedUser.value.telefono,
        },
        companyProfile: {
          denom: selectedUser.value.denom,
          p_iva: selectedUser.value.p_iva,
        },
        companyYearlyParams: selectedUser.value.yearlyParameters.find(yearlyParameters =>
          yearlyParameters.azienda_yearly_parameters.year.toString() === (toYear.value as string)
        ) || null,
      }
    });
    const accountInfo = reactive(defaultAccountInfo.value);

    const defaultCompanyName       = ref(accountInfo.companyProfile.denom);
    const defaultSector            = ref(accountInfo.companyYearlyParams.azienda_yearly_parameters.settore);
    const defaultMoneyRange        = ref(accountInfo.companyYearlyParams.azienda_yearly_parameters.fatturato);
    const defaultEmployeesRange    = ref(accountInfo.companyYearlyParams.azienda_yearly_parameters.employees_range);
    const defaultIsQuotedState     = ref(accountInfo.companyYearlyParams.azienda_yearly_parameters.is_quoted);
    const defaultIsAssociatedState = ref(accountInfo.companyYearlyParams.azienda_yearly_parameters.is_associated);
    const defaultCompanyType       = ref(accountInfo.companyYearlyParams.azienda_yearly_parameters.company_type);

    const componentKey = ref(0);
    
    const onResetFields = () => {
      componentKey.value += 1;

      accountInfo.companyProfile.denom = defaultCompanyName.value;
      accountInfo.companyYearlyParams.azienda_yearly_parameters.settore = defaultSector.value;
      accountInfo.companyYearlyParams.azienda_yearly_parameters.fatturato = defaultMoneyRange.value;
      accountInfo.companyYearlyParams.azienda_yearly_parameters.employees_range = defaultEmployeesRange.value;
      accountInfo.companyYearlyParams.azienda_yearly_parameters.is_quoted = defaultIsQuotedState.value;
      accountInfo.companyYearlyParams.azienda_yearly_parameters.is_associated = defaultIsAssociatedState.value;
      accountInfo.companyYearlyParams.azienda_yearly_parameters.company_type = defaultCompanyType.value;
    };

    const profileDetailsValidator = Yup.object().shape({
      denom: Yup.string()
        .required(translate("COMPANY_NAME_REQUIRED"))
        .label("Company name "),
      settore: Yup.string()
        .required(translate("INDUSTRIAL_SECTOR_REQUIRED"))
        .label("Sector name"),
      fatturato: Yup.string()
        .required(translate("USERNAME_REQUIRED"))
        .label("Fatturato"),
      employees_range: Yup.string()
        .required(translate("EMPLOYEE_RANGE_REQUIRED"))
        .nullable()
        .label("Range Dipendenti"),
      is_associated: Yup.string()
        .required(translate("MEMBERSHIP_REQUIRED"))
        .label("Webside"),
      is_quoted: Yup.string()
        .required(translate("LISTING_REQUIRED"))
        .label("Country"),
      company_type: Yup.string()
        .required(translate("COMPANY_TYPE_REQUIRED"))
        .label("Company Type"),
    });

    const selectLists = reactive(
      computed(() => store.getters.getSelectOptionLists)
    );

    const updateCompanyProfile = (values) => {     
      isFormSubmitted.value = true;

      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        Swal.fire({
          text: t("CHANGE_AZIENDA_SETTINGS_ALERT_MESSAGE"),
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: t("CHANGE_AZIENDA_SETTINGS_ALERT_CANCEL"),
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-dark-blue",
            cancelButton: "btn fw-bold btn-outline",
          },
        }).then(({ isConfirmed }) => {
          if (
            defaultCompanyType.value &&
            defaultCompanyType.value !== values.company_type
          ) {
            Swal.fire({
              text: t("CHANGE_COMPANY_TYPE_ALERT_MESSAGE"),
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: t("CHANGE_AZIENDA_SETTINGS_ALERT_CANCEL"),
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-dark-blue",
                cancelButton: "btn fw-bold btn-outline",
              },
            }).then(({ isConfirmed }) => {
              if (isConfirmed) {
                values.id   = JSON.parse(localStorage.getItem("simulate_user") as string).id;
                values.year = parseInt(localStorage.getItem("to_year_admin") as string);
                store.dispatch(
                  Actions.DELETE_SURVEY_PLUS_ADMIN,
                  route.params.id
                )
                .then(() => {
                  store
                    .dispatch(
                      Actions.UPDATE_COMPANY_PROFILE_ADMIN, 
                      values
                    )
                    .then(() => {
                      store.dispatch(
                        Actions.REQ_COMPANY_YEARLY_PARAMS,
                        JSON.parse(localStorage.getItem("simulate_user") as string).azienda_id
                      ).then((data) => {
                        delete data.latest;

                        let simulatedUser = JSON.parse(localStorage.getItem("simulate_user") as string);
                        simulatedUser.denom = values.denom;
                        for (let i = 0; i < simulatedUser.yearlyParameters.length; i++) {
                          if (simulatedUser.yearlyParameters[i].azienda_yearly_parameters.year === values.year) {
                            simulatedUser.yearlyParameters[i] = data;
                            break;
                          }
                        }
                        localStorage.setItem("simulate_user", JSON.stringify(simulatedUser));
                      })
                      successMessage(message_company_updated_succesfully.value);
                    })
                    .finally(() => {
                      submitButton.value?.removeAttribute("data-kt-indicator");
                    });
                })
              } else submitButton.value?.removeAttribute("data-kt-indicator");
            });
          } else {
            if (isConfirmed) {
            values.id   = JSON.parse(localStorage.getItem("simulate_user") as string).id;
            values.year = parseInt(localStorage.getItem("to_year_admin") as string);
            store
              .dispatch(
                Actions.UPDATE_COMPANY_PROFILE_ADMIN, 
                values
              )
              .then(() => {
                store.dispatch(
                  Actions.REQ_COMPANY_YEARLY_PARAMS,
                  JSON.parse(localStorage.getItem("simulate_user") as string).azienda_id
                ).then((data) => {
                  delete data.latest;

                  let simulatedUser = JSON.parse(localStorage.getItem("simulate_user") as string);
                  simulatedUser.denom = values.denom;
                  for (let i = 0; i < simulatedUser.yearlyParameters.length; i++) {
                    if (simulatedUser.yearlyParameters[i].azienda_yearly_parameters.year === values.year) {
                      simulatedUser.yearlyParameters[i] = data;
                      break;
                    }
                  }
                  localStorage.setItem("simulate_user", JSON.stringify(simulatedUser));
                })
                successMessage(message_company_updated_succesfully.value);
              })
              .finally(() => {
                submitButton.value?.removeAttribute("data-kt-indicator");
              });
            } else submitButton.value?.removeAttribute("data-kt-indicator");
          }
        });
      }
    };

    onMounted(() => {
      store.dispatch(Actions.REQ_INITIAL_COMPARE_SELECT_OPTIONS);

      setTimeout(() => {
        store.dispatch(
          Actions.CHANGE_CURRENT_YEARLY_PERIOD,
          localStorage.getItem("to_year_admin")
        );
      }, 1000);
    });

    return {
      submitButton,
      updateCompanyProfile,
      profileDetailsValidator,
      translate,
      ...toRefs(accountInfo),
      selectLists,
      translateKey,
      onResetFields,
      isFormSubmitted,
      componentKey,
      locale
    };
  },
});
